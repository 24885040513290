<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9992 91.6659C73.0111 91.6659 91.6659 73.0111 91.6659 49.9992C91.6659 26.9873 73.0111 8.33252 49.9992 8.33252C26.9873 8.33252 8.33252 26.9873 8.33252 49.9992C8.33252 73.0111 26.9873 91.6659 49.9992 91.6659Z"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50 33.3325V49.9992"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50 66.6675H50.0417"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
